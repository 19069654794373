import React from 'react'
import { graphql } from "gatsby"
import { Flex, Box } from 'reflexbox'
import { Helmet } from 'react-helmet'

import Container from '../components/Container'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Article from '../components/Article'
import Layout from '../components/layout'

const Index = ({ data, location }) => (
  <Layout>
    <Helmet>
      <title>CSU Ortsverband Kastl</title>
    </Helmet>
    <Header pathname={location.pathname}/>
      <Container>
        <Flex flexWrap='wrap' marginX={-3}>
          {
            data.graphcms.articles.map((article) => (
              <Box
                key={article.id}
                width={[ 1, 1/2 ]}
                px={3}
                pb={4}
              >
                <Article data={article} />
              </Box>
            ))
          }
      </Flex>
    </Container>
    <Footer />
  </Layout>
)

export default Index

export const pageQuery = graphql`
  query {
    graphcms {
      articles {
        id
        slug
        title
        summary
        image {
          url(transformation: { image: { resize: { fit: crop, height: 400, width: 700 } } })
        }
      }
    }
  }
`