import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import H2 from './H2'
import P from './P'

import Logo from '../../static/csu-logo-4c_pos-web.png'

const Content = styled(P)`
  color: ${props => props.theme.colors.black};
`

const Link = styled(GatsbyLink)`
  cursor: pointer;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px #e6e6e6, 0 1px 2px #d9d9d9;
  padding: 24px;
  display: block;
  text-decoration: none;

  &:hover {
    ${H2} {
      color: ${props => props.theme.colors.blue};
    }

    ${Content} {
      color: ${props => props.theme.colors.blue};
    }
  }
`

const ImageWrapper = styled.div`
  margin-bottom: 15px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
`

const Image = styled.img`
  height: auto;
  width: 100%;
`

const DummyImage = styled.img`
  display: block;
  height: auto;
  margin: 14% auto;
  opacity: 0.3;
  width: 50%;
`

const Article = ({ data }) => (
  <Link
    to={`artikel/${data.slug}`}
    rel='bookmark'
    title={data.title}
  >
    {
      data.image
        ? <ImageWrapper><Image src={data.image.url} /></ImageWrapper>
        : <ImageWrapper><DummyImage src={Logo} /></ImageWrapper>
    }
    <H2>{data.title}</H2>
    <Content>{data.summary}</Content>
  </Link>
)

export default Article